import _ from 'lodash'

import store from '../store/index'

export const routeAllowPermission = (inputPermission) => {
  const userPermissionObj = store.state.auth.user.permission
  const isAllow = _.get(userPermissionObj, inputPermission)

  if (!isAllow) {
    alert('ผู้ใช้งานไม่ได้รับสิทธิ์ให้เข้าถึงเนื้อหาส่วนนี้')
    return false
  }

  return true
}

export const routeAllowUserLevelAndAbove = (inputLevel) => {
  const isAllow = store.state.auth.user.userLevel >= inputLevel

  if (!isAllow) {
    alert('ผู้ใช้งานไม่ได้รับสิทธิ์ให้เข้าถึงเนื้อหาส่วนนี้')
    return false
  }

  return true
}
