export default {
  userId(state) {
    return state.userId
  },
  token(state) {
    return state.token
  },
  user(state) {
    return state.user
  },
  isAuthenticated(state) {
    return !!state.token
  }
}
