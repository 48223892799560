import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export default {
  state: {
    token: null,
    userId: null,
    user: null,
    company: null
  },
  getters,
  actions,
  mutations
}
