import client from '../../../services/api'

export default {
  setUserToken(state, token) {
    state.token = token

    localStorage.setItem('happysofttoken', token)
    client.defaults.headers.Authorization = `Bearer ${token}`
  },

  setUserInformation(state, payload) {
    state.userId = payload.userId
    state.user = payload
  },

  resetUser(state) {
    state.token = null
    state.userId = null
    state.user = null

    localStorage.removeItem('happysofttoken')
    client.defaults.headers.Authorization = null
  },

  setCompanyInformation(state, payload) {
    state.company = payload
  }
}
