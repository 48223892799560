import { routeAllowPermission } from '../../utils/route-permission'

const domain = '/user/basic-info'

export default [
  {
    path: `${domain}`,
    name: 'User.BasicInfo',
    component: () => import('@/views/user/basic-info/BasicInfo.vue')
  },
  {
    path: `${domain}/edit`,
    name: 'User.BasicInfo.Edit',
    component: () => import('@/views/user/basic-info/edit/Edit.vue')
  },
  {
    path: `${domain}/change-password`,
    name: 'User.BasicInfo.ChangePassword',
    component: () =>
      import('@/views/user/basic-info/change-password/ChangePassword.vue')
  }
]
