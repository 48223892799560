import { routeAllowUserLevelAndAbove } from '@/utils/route-permission'

const domain = '/admin/group-logon-masters'

export default [
  {
    path: `${domain}`,
    name: 'Admin.GroupLogonMasters',
    component: () =>
      import('@/views/admin/group-logon-master/GroupLogonMaster.vue'),
    meta: { allowUserLevelAndAbove: 99.99 }
  },
  {
    path: `${domain}/create`,
    name: 'Admin.GroupLogonMaster.Create',
    component: () =>
      import('@/views/admin/group-logon-master/create/Create.vue'),
    meta: { allowUserLevelAndAbove: 99.99 }
  },
  {
    path: `${domain}/detail/:groupCode`,
    name: 'Admin.GroupLogonMaster.Detail',
    component: () =>
      import('@/views/admin/group-logon-master/detail/Detail.vue'),
    meta: { allowUserLevelAndAbove: 99.99 }
  },
  {
    path: `${domain}/edit/:groupCode`,
    name: 'Admin.GroupLogonMaster.Edit',
    component: () => import('@/views/admin/group-logon-master/edit/Edit.vue'),
    meta: { allowUserLevelAndAbove: 99.99 }
  }
]
