const domain = '/report/classify'

export default [
  {
    path: `${domain}/trial-balance`,
    name: 'trial-balance',
    component: () =>
      import('@/views/report/classify/trial-balance/TrialBalance.vue'),
    meta: { allowPermission: 'reportTrialBalance.canView' }
  },
  {
    path: `${domain}/profit-and-loss-statement`,
    name: 'profit-and-loss-statement',
    component: () =>
      import(
        '@/views/report/classify/profit-and-loss-statement/ProfitAndLossStatement.vue'
      ),
    meta: { allowPermission: 'reportProfitAndLoss.canView' }
  },
  {
    path: `${domain}/balance-sheet`,
    name: 'balance-sheet',
    component: () =>
      import('@/views/report/classify/balance-sheet/BalanceSheet.vue'),
    meta: { allowPermission: 'reportBalanceSheet.canView' }
  },
  {
    path: `${domain}/working-paper`,
    name: 'working-paper',
    component: () =>
      import('@/views/report/classify/working-paper/WorkingPaper.vue'),
    meta: { allowPermission: 'reportWorkingPaper.canView' }
  },
  {
    path: `${domain}/account-activity`,
    name: 'account-activity',
    component: () =>
      import('@/views/report/classify/account-activity/AccountActivity.vue'),
    meta: { allowPermission: 'reportAccountActivity.canView' }
  }
]
