import { routeAllowUserLevelAndAbove } from '@/utils/route-permission'

const domain = '/admin/branches'

export default [
  {
    path: `${domain}`,
    name: 'Admin.Branches',
    component: () => import('@/views/admin/branch/Branch.vue'),
    meta: { allowUserLevelAndAbove: 99.99 }
  }
]
