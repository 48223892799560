import axios from 'axios'

import store from '../store/index'

const accessToken = localStorage.getItem('happysofttoken')
const baseURL = (() => {
  const environment = process.env.NODE_ENV
  switch (environment) {
    case 'local': {
      return process.env.VUE_APP_LOCAL_API_URL
    }
    case 'development': {
      return process.env.VUE_APP_DEVELOPMENT_API_URL
    }
    case 'production': {
      return process.env.VUE_APP_PRODUCTION_API_URL
    }
    default: {
      return process.env.VUE_APP_API_URL
    }
  }
})()

const client = axios.create({
  baseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`
  }
})

client.interceptors.response.use(
  (config) => {
    // Do nothing
    // console.log(config)
    return config
  },
  (error) => {
    const errorObj = error.response
      ? error.response.data
      : {
          status: 500,
          message: 'internal_server_error',
          thMessage: 'เกิดข้อผิดพลาดขึ้นในระบบ'
        }

    store.dispatch('setAppError', errorObj)

    throw error
  }
)

export default client
