import client from '../api'

const endpoint = '/auth'

export default {
  signup: async (body) => {
    return await client.post(`${endpoint}/signup`, body)
  },
  signupAdmin: async (body) => {
    return await client.post(`${endpoint}/signup-admin`, body)
  },
  login: async (body) => {
    return await client.post(`/happysoft-auth/login`, body)
  },
  getCompanyChoices: async (query) => {
    return await client.get(`/happysoft-auth/company-choices?${query}`)
  },
  getDatabaseChoices: async (query) => {
    return client.get(`/happysoft-auth/database-choices?${query}`)
  },

  // Group Logon Master
  getGroupLogonMasters: async ({ qs }) => {
    return await client.get(
      `${endpoint}/group-logon-masters${qs ? '?' + qs : ''}`
    )
  },
  getGroupLogonMaster: async (groupCode) => {
    return await client.get(`${endpoint}/group-logon-master/${groupCode}`)
  },
  createGroupLogonMaster: async (body) => {
    return await client.post(`${endpoint}/group-logon-master`, body)
  },
  updateGroupLogonMaster: async (groupCode, body) => {
    return await client.patch(
      `${endpoint}/group-logon-master/${groupCode}`,
      body
    )
  },
  deleteGroupLogonMaster: async (groupCode) => {
    return await client.patch(
      `${endpoint}/group-logon-master/${groupCode}/delete`
    )
  },

  // User Group
  getAvailableUserIdsForGroupCode: async (groupCode) => {
    return await client.get(
      `${endpoint}/available-user-ids-for-group-code/${groupCode}`
    )
  },
  getAvailableGroupCodesForUserId: async (userId) => {
    return await client.get(
      `${endpoint}/available-group-codes-for-user-id/${userId}`
    )
  },
  setUserIdsForGroupCode: async (body) => {
    return await client.patch(`${endpoint}/set-user-ids-for-group-code`, body)
  },
  setGroupCodesForUserId: async (body) => {
    return await client.patch(`${endpoint}/set-group-codes-for-user-id`, body)
  },

  // System Menu Access
  getSystemMenuAccesses: async ({ qs }) => {
    return await client.get(
      `${endpoint}/system-menu-accesses${qs ? '?' + qs : ''}`
    )
  },
  getSystemMenuAccess: async (id) => {
    return await client.get(`${endpoint}/system-menu-access/${id}`)
  },
  createSystemMenuAccess: async (body) => {
    return await client.post(`${endpoint}/system-menu-access`, body)
  },
  updateSystemMenuAccess: async (id, body) => {
    return await client.patch(`${endpoint}/system-menu-access/${id}`, body)
  },
  deleteSystemMenuAccess: async (id) => {
    return await client.patch(`${endpoint}/system-menu-access/${id}/delete`)
  },

  // System Menu Master
  getSystemMenuMasters: async () => {
    return await client.get(`${endpoint}/system-menu-masters`)
  }
}
