import client from '../api'

const endpoint = '/user'

export default {
  getOwnInformation: async () => {
    return await client.get(`${endpoint}/who-am-i`)
  },
  updateOwnBasicInformation: async (body) => {
    return await client.patch(`${endpoint}/basic-information`, body)
  },
  changeMyPassword: (body) => {
    return client.patch(`${endpoint}/change-my-password`, body)
  },
  getUserLogonMasters: async ({ qs }) => {
    return await client.get(
      `${endpoint}/user-logon-masters${qs ? '?' + qs : ''}`
    )
  },
  getUserLogonMaster: async (userId) => {
    return await client.get(`${endpoint}/user-logon-master/${userId}`)
  },
  updateUserLogonMaster: async (userId, body) => {
    return await client.patch(`${endpoint}/user-logon-master/${userId}`, body)
  },
  deleteUserLogonMaster: async (userId) => {
    return await client.patch(`${endpoint}/user-logon-master/${userId}/delete`)
  },
  setPassword: (userId, body) => {
    return client.patch(
      `${endpoint}/user-logon-master/${userId}/set-password`,
      body
    )
  }
}
