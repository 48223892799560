import _ from 'lodash'
import { mapState } from 'vuex'

export const toastMixins = {
  computed: {
    ...mapState({
      stateConstantsDefaultToastTimerMs: state =>
        state.constants.defaultToastTimerMs
    })
  },
  methods: {
    mxDefaultsDisplayMessage(message) {
      if (_.isNil(message)) {
        return 'เกิดข้อผิดพลาดขึ้นในระบบ'
      }
      return message
    },
    mxDisplaySuccessMessage(message) {
      let errorMessage = this.mxDefaultsDisplayMessage(message)
      this.$bvToast.toast(errorMessage, {
        title: `สำเร็จ`,
        toaster: 'b-toaster-bottom-right',
        variant: 'success',
        autoHideDelay: this.stateConstantsDefaultToastTimerMs,
        solid: false
      })
    },
    mxDisplayErrorMessage(message) {
      let errorMessage = this.mxDefaultsDisplayMessage(message)
      this.$bvToast.toast(errorMessage, {
        title: `ล้มเหลว`,
        toaster: 'b-toaster-bottom-right',
        variant: 'danger',
        autoHideDelay: this.stateConstantsDefaultToastTimerMs,
        solid: false
      })
    }
  }
}
