import { routeAllowUserLevelAndAbove } from '@/utils/route-permission'

const domain = '/admin/system-menu-accesses'

export default [
  {
    path: `${domain}`,
    name: 'Admin.SystemMenuAccesses',
    component: () =>
      import('@/views/admin/system-menu-access/SystemMenuAccess.vue'),
    meta: { allowUserLevelAndAbove: 99.99 }
  }
]
