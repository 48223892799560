<script>
export default {
  props: {
    title: String
  }
}
</script>

<template>
  <span class="page-title-style text-xxxl text-white">
    {{ title || '' }}
  </span>
</template>

<style scoped>
.page-title-style {
  text-shadow: 2px 2px grey;
}
</style>
