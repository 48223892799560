export default {
  namespaced: true,
  state: {
    defaultDelayMs: 1000,
    defaultToastTimerMs: 3000,
    defaultKeywordTimerMs: 6000
  },
  mutations: {},
  actions: {},
  getters: {}
}
