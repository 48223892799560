const domain = '/master'

export default [
  {
    path: `${domain}/ge1mast`,
    name: 'ge1mast',
    meta: { allowPermission: 'registrationMaster.canView' },
    component: () => import('@/views/master/ge1mast/Ge1mast.vue')
  }
]
