import admin from './admin.js'
import branch from './branch.js'
import userLogonMaster from './user-logon-master'
import groupLogonMaster from './group-logon-master'
import systemMenuAccess from './system-menu-access'

const adminRoutes = [
  ...admin,
  ...branch,
  ...userLogonMaster,
  ...groupLogonMaster,
  ...systemMenuAccess
]

export default adminRoutes
