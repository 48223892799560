const domain = '/entry'

export default [
  {
    path: `${domain}/ge2gjnl`,
    name: 'ge2gjnl',
    component: () => import('@/views/entry/ge2gjnl/Ge2gjnl.vue'),
    meta: { allowPermission: 'entryJournal.canView' }
  },
  {
    path: `${domain}/ge2gjnl/journal-book/:journalBookId/detail`,
    name: 'ge2gjnl.detail',
    component: () => import('@/views/entry/ge2gjnl/detail/Detail.vue'),
    meta: { allowPermission: 'entryJournal.canView' }
  },
  {
    path: `${domain}/ge2gjnl/journal-book/:journalBookId/create`,
    name: 'ge2gjnl.create',
    component: () => import('@/views/entry/ge2gjnl/create/Create.vue'),
    meta: { allowPermission: 'entryJournal.canAddNew' }
  },
  {
    path: `${domain}/ge2gjnl/journal-book/:journalBookId/edit/:journalCode/:documentId`,
    name: 'ge2gjnl.edit',
    component: () => import('@/views/entry/ge2gjnl/edit/Edit.vue'),
    meta: { allowPermission: 'entryJournal.canUpdate' }
  },
  {
    path: `${domain}/ge2gjnl/journal-document-templates`,
    name: 'ge2gjnl.journal-document-template',
    component: () =>
      import(
        '@/views/entry/journal-document-template/JournalDocumentTemplate.vue'
      ),
    meta: { allowPermission: 'entryJournalTemplate.canView' }
  },
  {
    path: `${domain}/ge2gjnl/journal-document-templates/create`,
    name: 'ge2gjnl.journal-document-template.create',
    component: () =>
      import('@/views/entry/journal-document-template/create/Create.vue'),
    meta: { allowPermission: 'entryJournalTemplate.canAddNew' }
  },
  {
    path: `${domain}/ge2gjnl/journal-document-templates/detail/:templateId`,
    name: 'ge2gjnl.journal-document-template.detail',
    component: () =>
      import('@/views/entry/journal-document-template/detail/Detail.vue'),
    meta: { allowPermission: 'entryJournalTemplate.canView' }
  },
  {
    path: `${domain}/ge2gjnl/journal-document-templates/edit/:templateId`,
    name: 'ge2gjnl.journal-document-template.edit',
    component: () =>
      import('@/views/entry/journal-document-template/edit/Edit.vue'),
    meta: { allowPermission: 'entryJournalTemplate.canUpdate' }
  },
  {
    path: `${domain}/ge2gjnl/journal-document-templates/duplicate/:templateId`,
    name: 'ge2gjnl.journal-document-template.duplicate',
    component: () =>
      import('@/views/entry/journal-document-template/duplicate/Duplicate.vue'),
    meta: { allowPermission: 'entryJournalTemplate.canAddNew' }
  }
]
