import _ from 'lodash'
import jwt from 'jsonwebtoken'
import moment from 'moment'

import authService from '../../../services/auth/auth'
import userService from '../../../services/user/user'
import basicInfoService from '../../../services/basic/basic-info'

export default {
  async signup(context, payload) {
    const {
      userId,
      password,
      passwordConfirm,
      userName,
      userLevel,
      userExpiredAt
    } = payload

    const signupObj = {
      userId,
      password,
      passwordConfirm,
      hasppysoftSignupCode: process.env.SIGNUP_CODE,
      userName,
      userLevel,
      userExpiredAt
    }
    await authService.signup(signupObj)

    const loginObj = { userId, password }
    const loginResult = await authService.login(loginObj)
    context.commit('setUserToken', loginResult.data.token)

    const getOwnInfoResult = await userService.getOwnInformation()
    context.commit('setUserInformation', getOwnInfoResult.data)
  },

  async signupAdmin(context, payload) {},

  async login(context, payload) {
    const { email, companyId, databaseId, username, password } = payload

    const loginObj = { email, companyId, databaseId, username, password }
    const loginResult = await authService.login(loginObj)
    context.commit('setUserToken', loginResult.data.data.token)
    context.dispatch('autoLogout', { token: loginResult.data.data.token })

    await context.dispatch('fetchOwnInformation')
  },

  async autoLogin(context, payload) {
    const token = localStorage.getItem('happysofttoken')
    if (token) {
      context.commit('setUserToken', token)
      context.dispatch('autoLogout', { token })

      await context.dispatch('fetchOwnInformation')
    }
  },

  logout(context, payload) {
    context.commit('resetUser')
  },

  autoLogout(context, payload) {
    const { token } = payload

    let decoded = jwt.decode(token)

    if (
      !_.isNil(decoded.exp) &&
      _.isNumber(decoded.exp) &&
      _.isInteger(decoded.exp)
    ) {
      if (moment().isSameOrAfter(moment(decoded.exp * 1000))) {
        context.dispatch('tokenExpireLogout')
      } else {
        const momentUnixTimeMs = moment().unix() * 1000
        const expireUnixTimeMs = decoded.exp * 1000
        const timeDifference = expireUnixTimeMs - momentUnixTimeMs
        const maximumSetTimeoutDelayMs = 24.85 * 24 * 60 * 60 * 1000
        const delayInMs =
          timeDifference < maximumSetTimeoutDelayMs
            ? timeDifference
            : maximumSetTimeoutDelayMs
        setTimeout(() => {
          context.dispatch('tokenExpireLogout')
        }, delayInMs)
      }
    }
  },

  tokenExpireLogout(context, payload) {
    context.dispatch('setAppError', {
      status: 401,
      message: 'token_expire',
      thMessage: 'โทเค็นหมดอายุ กรุณาเข้าสู่ระบบใหม่อีกครั้ง'
    })
    context.dispatch('logout')
  },

  async fetchOwnInformation(context, payload) {
    const getOwnInfoResult = await userService.getOwnInformation()
    context.commit('setUserInformation', getOwnInfoResult.data.data)

    const getCompanyBasicInfoResult = await basicInfoService.getCompanyInfo()
    context.commit('setCompanyInformation', getCompanyBasicInfoResult.data.data)
  }
}
