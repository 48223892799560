import { routeAllowUserLevelAndAbove } from '@/utils/route-permission'

const domain = '/admin/user-logon-masters'

export default [
  {
    path: `${domain}`,
    name: 'Admin.UserLogonMasters',
    component: () =>
      import('@/views/admin/user-logon-master/UserLogonMaster.vue'),
    meta: { allowUserLevelAndAbove: 99.99 }
  },
  {
    path: `${domain}/detail/:userId`,
    name: 'Admin.UserLogonMaster.Detail',
    component: () =>
      import('@/views/admin/user-logon-master/detail/Detail.vue'),
    meta: { allowUserLevelAndAbove: 99.99 }
  },
  {
    path: `${domain}/edit/:userId`,
    name: 'Admin.UserLogonMaster.Edit',
    component: () => import('@/views/admin/user-logon-master/edit/Edit.vue'),
    meta: { allowUserLevelAndAbove: 99.99 }
  },
  {
    path: `${domain}/create`,
    name: 'Admin.UserLogonMaster.Create',
    component: () =>
      import('@/views/admin/user-logon-master/create/Create.vue'),
    meta: { allowUserLevelAndAbove: 99.99 }
  },
  {
    path: `${domain}/create-admin`,
    name: 'Admin.UserLogonMaster.CreateAdmin',
    component: () =>
      import('@/views/admin/user-logon-master/create-admin/CreateAdmin.vue'),
    meta: { allowUserLevelAndAbove: 99.99 }
  },
  {
    path: `${domain}/edit-admin/:userId`,
    name: 'Admin.UserLogonMaster.EditAdmin',
    component: () =>
      import('@/views/admin/user-logon-master/edit-admin/EditAdmin.vue'),
    meta: { allowUserLevelAndAbove: 99.99 }
  },
  {
    path: `${domain}/set-password/:userId`,
    name: 'Admin.UserLogonMaster.SetPassword',
    component: () =>
      import('@/views/admin/user-logon-master/set-password/SetPassword.vue'),
    meta: { allowUserLevelAndAbove: 99.99 }
  }
]
