const domain = '/master'

export default [
  {
    path: `${domain}/account-receivable/list`,
    name: 'AccountReceivable.List',
    meta: { allowPermission: 'registrationAR.canView' },
    component: () =>
      import('@/views/master/account-receivable/list/AccountReceivableList.vue')
  },
  {
    path: `${domain}/account-receivable/create`,
    name: 'AccountReceivable.Create',
    meta: { allowPermission: 'registrationAR.canAddNew' },
    component: () =>
      import(
        '@/views/master/account-receivable/create/AccountReceivableCreate.vue'
      )
  },
  {
    path: `${domain}/account-receivable/:id/edit`,
    name: 'AccountReceivable.Edit',
    meta: { allowPermission: 'registrationAR.canUpdate' },
    component: () =>
      import('@/views/master/account-receivable/edit/AccountReceivableEdit.vue')
  },
  {
    path: `${domain}/account-receivable/:id/detail`,
    name: 'AccountReceivable.Detail',
    meta: { allowPermission: 'registrationAR.canView' },
    component: () =>
      import(
        '@/views/master/account-receivable/detail/AccountReceivableDetail.vue'
      )
  }
]
