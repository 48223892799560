<script>
import { mapState } from 'vuex'

import { toastMixins } from './mixins/toast'

export default {
  mixins: [toastMixins],
  data() {
    return {
      errMsg: null
    }
  },
  computed: {
    ...mapState({
      appError: 'appError'
    })
  },
  watch: {
    appError() {
      if (this.appError && this.$route.name !== 'Login') {
        switch (this.appError.message) {
          case 'user_expire':
          case 'user_password_expire':
          case 'user_has_changed_password':
          case 'user_does_not_exist':
          case 'invalid_token':
          case 'token_expire':
          case 'not_logged_in':
          case 'user_not_allow_in_this_company':
          case 'user_not_allow_in_this_database':
            this.$store.dispatch('logout')
            setTimeout(() => {
              location.reload()
            }, this.$store.state.constants.defaultDelayMs)
            break
          default:
            break
        }
        this.$store.dispatch('setAppError', null)
      }
    }
  },
  created() {
    const tokenExist = !!localStorage.getItem('happysofttoken')
    const alreadyHaveUserInfo = this.$store.state.auth.user

    if (tokenExist && !alreadyHaveUserInfo) {
      this.$store.dispatch('autoLogin').catch((err) => {
        this.errMsg = err.response.data && err.response.data.thMessage
      })
    }
  }
}
</script>

<template>
  <div class="main-div-bg-style overflow-auto">
    <router-view class="router-view-bg-style"></router-view>

    <error-modal
      :displayProp="!!errMsg"
      :errorMessageProp="errMsg"
      @modalClosed="errMsg = null"
    ></error-modal>
  </div>
</template>

<style>
.main-div-bg-style {
  background-image: linear-gradient(#0d47a1, #e3f2fd);
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  height: 100vh;
}
.router-view-bg-style {
  background-image: linear-gradient(#0d47a1, #e3f2fd);
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.text-xxxl {
  font-size: 1.8rem;
}
.text-xxl {
  font-size: 1.6rem;
}
.text-xl {
  font-size: 1.4rem;
}
.text-lg {
  font-size: 1.2rem;
}
.text-md {
  font-size: 1rem;
}
.text-sm {
  font-size: 0.8rem;
}
.text-xs {
  font-size: 0.6rem;
}
</style>
