const domain = '/basic/basic-info'

export default [
  {
    path: `${domain}`,
    name: 'Basic.BasicInfo',
    component: () => import('@/views/basic/basic-info/BasicInfo.vue')
  },
  {
    path: `${domain}/edit`,
    name: 'Basic.BasicInfo.Edit',
    component: () => import('@/views/basic/basic-info/edit/Edit.vue')
  }
]
