<script>
/**
 * Props:
 *  displayProp: boolean
 *    ตัวควบคุมการเปิดปิด modal
 *  errorMessageProp: String
 *    แสดงข้อความ error
 * Events:
 *  modalClosed
 *    payload: undefined
 *    เมื่อกดปุ่มตกลงจะ emit event นี้
 */

import { isNil } from 'lodash'

export default {
  props: {
    displayProp: Boolean,
    errorMessageProp: String
  },
  data() {
    return {
      display: false,
      errorMessage: 'ระบบทำงานผิดพลาด'
    }
  },
  watch: {
    displayProp() {
      this.display = this.displayProp
    },
    errorMessageProp() {
      if (isNil(this.errorMessageProp)) {
        this.errorMessage = 'เกิดข้อผิดพลาดขึ้นในระบบ'
      }
      this.errorMessage = this.errorMessageProp
    }
  },
  methods: {
    confirmButtonClicked() {
      this.$emit('modalClosed')
    }
  }
}
</script>

<template>
  <b-modal
    v-model="display"
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close
    hide-footer
    header-bg-variant="danger"
    header-text-variant="light"
    title="ผิดพลาด"
    size="md"
  >
    <b-row>
      <!-- error icon -->
      <b-col cols="12" class="d-flex justify-content-center">
        <b-icon
          icon="exclamation-circle"
          variant="danger"
          class="mt-3 mb-5"
          style="width: 100%; height: 100px;"
        ></b-icon>
      </b-col>

      <!-- error message -->
      <b-col cols="12">
        <div class="mb-5 text-center text-danger text-lg">
          <span>{{ errorMessage }}</span>
        </div>
      </b-col>

      <!-- buttons -->
      <b-col cols="12">
        <b-row align-h="center">
          <b-col cols="5">
            <b-button
              class="w-100 mb-2"
              variant="outline-danger"
              @click="confirmButtonClicked()"
            >
              ตกลง
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-modal>
</template>

<style scoped></style>
