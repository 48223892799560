import Vue from 'vue'
import VueRouter from 'vue-router'
import { isEmpty } from 'lodash'

import MasterRoutes from './master/index.js'
import EntryRoutes from './entry/index.js'
import ReportRoutes from './report/index.js'
import AdminRoutes from './admin/index.js'
import UserRoutes from './user/index.js'
import BasicRoutes from './basic/index.js'

import {
  routeAllowPermission,
  routeAllowUserLevelAndAbove
} from '../utils/route-permission.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue')
  },
  ...AdminRoutes,
  ...EntryRoutes,
  ...MasterRoutes,
  ...ReportRoutes,
  ...UserRoutes,
  ...BasicRoutes
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const hasToken = !!localStorage.getItem('happysofttoken')
  const goToLoginPage = to.name === 'Login'

  if ((!goToLoginPage && hasToken) || (goToLoginPage && !hasToken)) {
    next()
  } else {
    if (!goToLoginPage && !hasToken) next({ name: 'Login' })
    if (goToLoginPage && hasToken) next({ name: 'Home' })
  }
})

router.beforeResolve((to, from, next) => {
  const redirectToHome = () => {
    if (from.name !== 'Home') next({ name: 'Home' })
    else return
  }

  if (!isEmpty(to.meta)) {
    const { allowPermission, allowUserLevelAndAbove } = to.meta

    switch (true) {
      case allowPermission && !routeAllowPermission(allowPermission):
      case allowUserLevelAndAbove &&
        !routeAllowUserLevelAndAbove(allowUserLevelAndAbove):
        return redirectToHome()
    }
  }

  next()
})

export default router
