import axios from '../api'

const endpoint = '/company/basic-info'

export default {
  getCompanyInfo: () => {
    return axios.get(`${endpoint}`)
  },
  updateCompanyInfo: body => {
    return axios.patch(`${endpoint}`, body)
  }
}
