import Vue from 'vue'
import Vuex from 'vuex'

import moduleAuth from './modules/auth'
import moduleConstants from './modules/constants'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth: moduleAuth,
    constants: moduleConstants
  },
  state: {
    appLoading: false,
    appError: null
  },
  mutations: {
    setAppLoading: (state, loadingStatus) => {
      state.appLoading = loadingStatus
    },
    setAppError: (state, error) => {
      state.appError = error
    }
  },
  actions: {
    setAppLoading: (context, loadingStatus) => {
      context.commit('setAppLoading', loadingStatus)
    },
    setAppError: (context, error) => {
      context.commit('setAppError', error)
    }
  }
})
